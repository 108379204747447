import React, { useEffect, useState } from "react";
import "../../style/view/chambers.css"
import { SelectAlphaIcon } from "../input/SelectAlphaIcon";
import { SearchInputButtonComponent } from "../input/SearchInputButtonComponent";
import { Table } from "../table/table";
import SVGraphics from "../../assets/SVGraphics";
import { useNavigate } from "react-router-dom";
import ChambersApi from "../../services/ApiServices/ChambersApi";
import Constants from "../../utils/constants";
import moment from "moment";
import { useAppDispatch } from "../../redux/hooks";
import { setChambersData } from "../../redux/reducer/chambersReducer";
import { authenticationService } from "../../services/AuthinticationService";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function Chambers(props) {

    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [chambers, setChambers] = useState([]);
    const [searchInputs, setSearchInputs] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [chambersFilter, setChambersFilter] = useState({ filters: [] });


    useEffect(() => {
        let filter = { filters: [] }
        fetchData(filter).then()
        let user = authenticationService.currentUserValue
        setUserRole(user.role[0])
        const _searchInputs = user.role[0] === "SystemAdmin" ? Constants.chambers_searchInputs_system : Constants.chambers_searchInputs
        const fetchDataGetChambersFilters = async () => {
            let filters = await ChambersApi.getChamberFiltersData()
            if (filters && filters.data) {
                filters = filters.data
                for (let key in filters) {
                    if (filters.hasOwnProperty(key)) {
                        let newData = []
                        filters[key].forEach(data => {
                            if (data !== null) {
                                newData.push(data)
                            }
                        })
                        filters[key] = newData
                    }
                }
                _searchInputs.forEach(input => {
                    if (input.name === 'CurrentStatus') {
                        input['data'] = filters[input.name]?.map(d => { return { value: d, label: d ? 'In Use' : 'Free' } })
                    } else if (input.name === 'SiteID') {
                        input['data'] = filters['Site']?.map(d => { return { value: d.SiteID, label: d.SiteName } })
                    }
                    else if (input.name !== 'SiteID' && input.name !== 'Status' && input.name !== 'DateRange') {
                        input['data'] = filters[input.name]?.map(d => { return { value: d, label: d } })
                    }
                })
            }
            await setSearchInputs(_searchInputs)
        }
        fetchDataGetChambersFilters().then()

    }, [props.currentUserRole])
    const fetchData = async (filter) => {
        let data = await ChambersApi.getAllChambers(filter)
        if (data && data.data) {
            data.data.forEach(d => {
                d['CurrentStatus'] = d['CurrentStatus'] === true ? 'In Use' : 'Free'
            })
            setChambers(data.data)
            setFilteredData(data.data)
            setIsLoading(false)
            // dispatch(setChambersData(data.data))
        }
    }

    const handleSearch = (event) => {
        let filterValue = event.target.value.toLowerCase()
        let filteredData = []
        for (let i = 0; i < chambers.length; i++) {
            filteredData = chambers.filter(chamber =>

                String(chamber['GloveBoxNumber'])?.toLowerCase().indexOf(filterValue) > -1 ||
                String(chamber['Index'])?.toLowerCase().indexOf(filterValue) > -1 ||
                String(chamber['ChamberID'])?.toLowerCase().indexOf(filterValue) > -1 ||
                String(chamber['ActivityLevel'])?.toLowerCase().indexOf(filterValue) > -1 ||
                String(chamber['Status'])?.toLowerCase().indexOf(filterValue) > -1 ||
                String(chamber['CurrentStatus']).toLowerCase().indexOf(filterValue) > -1 ||
                String(chamber['Site']['SiteName'])?.toLowerCase().indexOf(filterValue) > -1 ||
                String(chamber['ActivityDate'])?.toLowerCase().indexOf(filterValue) > -1
            )
        }
        setFilteredData(filteredData)
    }

    const columns = [
        {
            Header: 'Glove Box',
            accessor: 'GloveBoxNumber', // accessor is the "key" in the data
            // sortType: 'string'
        },
        {
            Header: 'Chamber Index',
            accessor: 'Index',
            // sortType: 'string'
        },
        {
            Header: 'Chamber ID',
            accessor: 'ChamberID',
            // sortType: 'string'
        },
        {
            Header: 'Activity Date',
            accessor: 'ActivityDate',
            // sortType: 'string'
            Cell: (props) => {
                return (props.row.original.ActivityDate ? moment(props.row.original.ActivityDate).format('DD/MM/YYYY') : '')
            }
        },
        {
            Header: 'Asymptotic Activity (kBq)',
            accessor: 'ActivityLevel',
            // sortType: 'string'
        },
        {
            Header: 'Status',
            accessor: 'Status',
            // sortType: 'string',
            Cell: (props) => {
                let status = props.row.original.Status
                let divClass, labelClass, text
                if (status === 'active') {
                    divClass = "statusTableDivActive"
                    labelClass = "statusTableDivTextActive"
                    text = "Active"
                } else if (status === 'maintenance' && props.row.original.process !== "charge") {
                    divClass = "statusTableDivOnHold"
                    labelClass = "statusTableDivTextOnHold"
                    text = "Maintenance"
                } else if (status === 'maintenance' && props.row.original.process === "charge") {
                    divClass = "statusTableDivOnHold"
                    labelClass = "statusTableDivTextOnHoldWithOrder"
                    text = "Maintenance With Order"
                } else if (status === 'Disabled') {
                    divClass = "statusTableDivDisable"
                    labelClass = "statusTableDivTextDisable"
                    text = "Disable"
                }
                return (
                    <OverlayTrigger
                        placement={"bottom-end"}
                        delay={{ show: 250, hide: 250 }}
                        trigger={["hover", "focus"]}
                        rootClose
                        // show={true}
                        overlay={<Tooltip id="" className={"tableToolTip right-[-1rem]"}>
                            <div className={""}>{text}</div>
                        </Tooltip>}>
                        <div className={divClass}>
                            <label className={labelClass}>{text}</label>
                        </div>
                    </OverlayTrigger>
                    // <OverlayTrigger trigger={['hover','focus']} delay={{show: 250, hide: 250}}
                    //                 overlay={<Tooltip id="tooltip-disabled" className={"versionToolTip"}>
                    //     <label className={'text-slate-400 px-4 py-10'}>{text}</label>
                    // </Tooltip>} placement={'bottom'}> <div className={divClass}>
                    //     <label className={labelClass}>{text}</label>
                    // </div>
                    // </OverlayTrigger>


                )
            }
        },
        {
            Header: 'Availability',
            accessor: 'CurrentStatus',
            Cell: (props) => {
                return (
                    <div className={`text-[1.125rem] ${props.row.original.CurrentStatus === 'In use' ? 'text-[#444444]' : 'text-[#ACAEB1]'}`}>
                        {props.row.original.CurrentStatus}
                    </div>
                )
            }
            // sortType: 'string'
        },
        {
            Header: 'Site',
            accessor: 'Site.SiteName',
            // sortType: 'string'
        },
        {
            Header: 'Edit',
            accessor: 'Edit',
            Cell: (cell) => {
                return (<div className={'cursor-pointer'} onClick={() => navigate(`/editChamber/${cell.row.original?.chamberInternalID}`)}>
                    <SVGraphics className={'w-6 h-6'} svgname={'edit'} />
                </div>
                )
            }
        },
    ]

    const clearFilters = async () => {
        let filter = { filters: [] }
        setChambersFilter(filter)
        setStartDate(null)
        setEndDate(null)
        fetchData(filter).then()
    }

    const onChangeDateFilter = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        let filters = { ...chambersFilter }
        let filter = filters.filters.findIndex(x => x.name === 'DateRange')
        if (filter > -1) {
            if (!start && !end) {
                filters['filters'].splice(filter, 1)
            } else {
                filters['filters'][filter]['value'] = { startDate: start, endDate: end }
            }
        } else {
            filters.filters.push({ name: 'DateRange', value: { startDate: start, endDate: end } })
        }
        setChambersFilter(filters)
        fetchData(filters).then()
    };
    const onChangeFilter = (e) => {
        let filters = { ...chambersFilter }
        let filter = filters.filters.findIndex(x => x.name === e.target.name)
        if (filter > -1) {
            if (!e.target.value) {
                filters['filters'].splice(filter, 1)
            } else {

                filters['filters'][filter]['value'] = e.target.value.value
            }
        } else {
            filters.filters.push({ name: e.target.name, value: e.target.value.value })
        }
        setChambersFilter(filters)
        fetchData(filters).then()
    };

    return (
        <div className={Constants.mainScreenClass}>
            <div className={"site-search-inputs"}>
                {searchInputs.map(item => {
                    return <SelectAlphaIcon
                        key={item.name}
                        svgName={item.svgName}
                        data={item.data}
                        type={item.type}
                        value={chambersFilter['filters'].find(filter => filter.name === item.name)?.value}
                        selected={chambersFilter['filters'].find(filter => filter.name === item.name)?.value}
                        name={item.name}
                        label={item.label}
                        isRange={true}
                        onChange={(e) => onChangeFilter(e)}
                        onChangeDate={onChangeDateFilter}
                        startDate={startDate}
                        endDate={endDate}
                        hasLine={item.hasLine}
                        inputClassName={'Select-Icon'}
                    />
                })}
                <div className={'ml-[3rem] lg:ml-4 text-alphaTauButton text-[1.25rem] h-[3.5rem] font-bold font-Roboto flex items-center cursor-pointer'} onClick={() => clearFilters()}><span className={'mr-4'}>&#10005;</span> Clear all</div>
            </div>
            <div className={"w-width90 lg:w-[98%]"}>
                <SearchInputButtonComponent
                    buttonText={"+ Add new chamber"}
                    hasButton={userRole === 'SystemAdmin' || userRole === 'ProductAdmin'}
                    inputClass={""}
                    addNewOnClick={() => navigate('/addChamber')}
                    onChange={(e) => handleSearch(e)} />
                <Table data={filteredData} columns={columns} isLoading={isLoading} shouldMarkRow={'needToUpdateActivity'} />
            </div>

        </div>
    )
}
